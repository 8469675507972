<template>
  <b-container>
    <b-row>
      <b-col>
        <b>Client Status Filter:</b>
        <b-form-select
          v-model="status"
          class="m-4"
          style="width: 300px"
          :options="statuses"
        />
        <span>
          <b>Counter:</b> {{ filteredBusinesses.length }}
        </span>
      </b-col>
    </b-row>
    <b-card
      v-for="business in filteredBusinesses"
      :key="business.id"
      class="mb-4"
    >
      <b-row>
        <b-col
          class="mt-2"
          cols="1"
        >
          <img
            v-if="business.logo"
            :src="business.logo"
            alt="Pusiness logo"
            class="img-fluid logo"
          >
        </b-col>
        <b-col
          class="m-1 d-inline-flex"
          cols="10"
        >
          <b-col class="m-1">
            <p class="mb-0">
              <b> {{ business.name }} </b>
            </p>
            <p class="mb-0">
              <b>Status:</b> {{ business.status }}
            </p>
            <p class="mb-0">
              <b>CSM:</b> {{ business.success_manager }}
            </p>
          </b-col>
          <b-col class="m-1">
            <a
              v-if="business.website"
              target="_blank"
              :href="business.website"
            >
              Business Website
            </a>
            <p class="mb-0">
              <b>HQ:</b> {{ business.headquarters }}
            </p>
            <p class="mb-0">
              <b>Employees:</b> {{ business.employees }}
            </p>
          </b-col>
        </b-col>
      </b-row>
      <div>
        <b-btn
          variant="success"
          :to="`/admin/business/${business.id}/edit`"
        >
          Edit
        </b-btn>
        <b-btn
          variant="danger"
          class="float-right"
          @click="remove(business.id)"
        >
          Delete
        </b-btn>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import axios from '@/helpers/axios'
import { BUSINESS_STATUSES, statusToArray } from '@/helpers/statuses'

export default {
  name: 'BusinessList',
  data () {
    return {
      businesses: [],
      status: null,
      statuses: [
        { value: null, text: 'All' },
        ...statusToArray(BUSINESS_STATUSES),
      ],
    }
  },
  computed: {
    filteredBusinesses () {
      let businesses = this.businesses
      const status = (this.status || '').toLowerCase()
      if (status) {
        businesses = businesses.filter(r => (r.status || '').toLowerCase() === status)
      }
      return businesses
    },
  },
  created () {
    this.getBusinesses()
  },
  methods: {
    getBusinesses () {
      return axios.get('/admin/v2/businesses')
        .then(({ data }) => {
          this.businesses = data
        })
    },
    remove (businessId) {
      const confirmed = confirm('U are about to delete a business, sure you want to do it?')
      if (confirmed) {
        axios.delete(`/admin/v2/businesses/${businessId}`)
          .then(() => {
            this.getBusinesses()
              .then(() => alert('Deleted'))
          })
      }
    },
  },
}
</script>

<style>

</style>
